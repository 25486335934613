<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="params" sort-by="name" disable-pagination hide-default-footer>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Parametrisierung</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Eintrag bearbeiten</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="editedItem.value" label="Wert"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.description" label="Beschreibung"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Abbrechen
                    </v-btn>
                    <v-btn color="green darken-1" @click="save">
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            Keine Daten
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6">
        <v-file-input v-model="file" :placeholder="excelInfo['value']" :accept="['.xlsx']"></v-file-input>
      </v-col>
      <v-col cols="6">
        <v-btn @click="uploadExcel">UploadExcel</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { BACKEND_URL } from '@/za_conf'

export default {
  name: 'Params',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    params: [],
    excelInfo: {},
    file: null,
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'name',
      },
      { text: 'Wert', value: 'value' },
      { text: 'Beschreibung', value: 'description' },
      { text: 'Bearbeiten', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: '',
      value: '',
      description: '',
    },
    defaultItem: {
      name: '',
      value: '',
      description: '',
    },
  }),

  computed: {},

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.updateParams()
  },

  methods: {
    updateParams() {
      axios
        .get(`${BACKEND_URL}/params`)
        .then(response => {
          if (response.status === 200) {
            this.excelInfo = response.data['INDIVIDUAL_EXCEL']
            delete response.data['INDIVIDUAL_EXCEL']
            this.params = Object.values(response.data)
          }
        })
        .catch(error => {})
    },
    uploadExcel() {
      const formData = new FormData()
      formData.set('file', this.file)
      axios.post(`${BACKEND_URL}/upload_excel`, formData).then(response => {
        this.excelInfo = response.data['INDIVIDUAL_EXCEL']
        delete response.data['INDIVIDUAL_EXCEL']
        this.params = Object.values(response.data)
      })
    },
    editItem(item) {
      // TODO SEND TO SERVER
      this.editedIndex = this.params.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      // TODO SEND TO SERVER AND REFRESH VALUES
      axios
        .post(`${BACKEND_URL}/params`, { ...this.editedItem })
        .then(response => {
          if (response.status === 200) {
            this.excelInfo = response.data['INDIVIDUAL_EXCEL']
            delete response.data['INDIVIDUAL_EXCEL']
            this.params = Object.values(response.data)
          }
        })
        .catch(error => {})
      this.close()
    },
  },
}
</script>

<style scoped></style>
